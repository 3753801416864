import React from 'react';
import { styled } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

const StyledSnackbar = styled(Snackbar)({
  '&.MuiSnackbar-anchorOriginTopCenter': {
    left: '75%',
    width: '45%',
    fontWeight: 400,
    fontSize: '16px',
  },
});

const SnackbarContent = styled('div')({
  color: '#000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '8px',
  padding: '10px 20px',
  backgroundColor: '#fff',
  boxShadow: '0px 8px 10px 0px #00000026'
});

const MessageContainer = styled('span')({
  display: 'flex',
  alignItems: 'center',
});

const ErrorIconStyled = styled(ErrorIcon)({
  color: '#f44336',
  marginRight:'8px'
});

const SuccessIconStyled = styled(CheckCircleIcon)({
  color: '#4caf50',
  marginRight:'8px'
});

const InfoIconStyled = styled(InfoIcon)({
  color: '#2196f3',
  marginRight:'8px'
});

const WarningIconStyled = styled(WarningIcon)({
  color: '#ff9800',
  marginRight:'8px'
});

interface ToastNotificationProps {
  open: boolean;
  message: string;
  type: string;
  onClose: ()=> void;
}

const ToastNotification: React.FC<ToastNotificationProps> = ({ open, message, onClose, type }) => {

  const getIcon = () => {
    switch (type) {
      case 'success':
        return <SuccessIconStyled />;
      case 'error':
        return <ErrorIconStyled />;
      case 'info':
        return <InfoIconStyled />;
      case 'warning':
        return <WarningIconStyled />;
      default:
        return null;
    }
  };

  return (
    <StyledSnackbar
        data-test-id="snackbar"
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <SnackbarContent>
        <MessageContainer>
          {getIcon()}
          {message}
        </MessageContainer>
      </SnackbarContent>
    </StyledSnackbar>
  );
};

export default ToastNotification;