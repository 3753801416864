import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Modal
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { crossSigin, success, black_cross_sigin } from "../src/assets";
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import MessageBox from "../../../components/src/messageBox";
import { ChangeEvent, CSSProperties } from "react";
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  [x: string]: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  displayDataErrorMessage = () => {
    return(
      <Box style={{display: 'none'}}>
        <Typography>No data found.</Typography>
      </Box>
    );
  }
  
  displayApiErrorMessage = () => {
    return(
      <Box style={{display: 'none'}}>
        <Typography>Api response failed.</Typography>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MyProfileHeader   navigation={this.props.navigation} showSidebar={false}>
        {this.state.messageToggle && <MessageBox message={this.state.tostifymessage} status={this.state.status}/>}
        <style>
          {`
            Grid{
              border:2px solid red;
            }
          `}
        </style>
        {this.state.tempToggle ?
        <Box component="div" style={StyleSheet.ContainerBox}>
          <Button
            data-test-id="back-page"
            style={StyleSheet.BackBtn as CSSProperties}
            onClick={this.backPasswordBtn}
          >
            <span style={{ fontSize: "20px" }}> &#60; </span>&nbsp; Back
          </Button>
          <Box style={{ padding: "30px 32px", backgroundColor: "white" }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <h2 style={{ margin: "0px", fontSize: "18px", color: "#0F172A" }}>Change Password</h2>
                <p style={{ margin: "0px", fontSize: "14px", color: "#475569" }}>Please fill in below details to change account password.</p>
              </Grid>
              <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
                <Button
                  data-test-id="resetAllPasswordField"
                  onClick={this.resetAllPasswordField}
                  style={StyleSheet.cancelBtn as CSSProperties}>
                  Cancel
                </Button>
                <Button
                  data-test-id="savePasswordChanges"
                  style={this.desiableCheck() ? StyleSheet.saveChanges as CSSProperties : StyleSheet.saveChangesDisabled as CSSProperties}
                  disabled={!this.desiableCheck()}
                  onClick={this.savePassword}
                >
                  Save Changes
                </Button>
              </Grid>

              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <Grid container style={StyleSheet.rowReverse as CSSProperties}>
                  <Grid item xs={12} md={6}>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                      <Box style={{position:"relative"}}>
                        <label style={StyleSheet.inputLabel}> Current Password </label>
                        <input type="text" name="currentPassword" 
                          style={StyleSheet.InputText}
                          value={this.state.showCurrentPassword ? this.state.asteriskCurrent : this.state.originalCurrentPassword}
                          data-test-id="handleCurrentPasswordChange"
                          onChange={(e:ChangeEvent<HTMLInputElement>) => this.handleCurrentPasswordChange(e)}
                          placeholder="Enter your current password"
                        />
                         {this.state.showCurrentPassword ? (
                          <VisibilityOffOutlinedIcon
                            data-test-id="IconShowCurrentPassword"
                            style={StyleSheet.EyePassward as CSSProperties}
                            onClick={this.toggleCurrentVisibility}
                          />
                        ) : (
                          <VisibilityOutlinedIcon
                            data-test-id="IconHideCurrentPassword"
                            style={StyleSheet.EyePassward as CSSProperties}
                            onClick={this.toggleCurrentVisibility}
                          />
                        )}
                      </Box>
                      <Box style={{position:"relative"}}>
                        <label style={StyleSheet.inputLabel}>New Password</label>
                        <input type="text" name="newPassword"
                          style={StyleSheet.InputText}
                          value={this.state.showPassword ? this.state.asterisk : this.state.originalPassword}
                          data-test-id="handleNewPasswordChanged"
                          onChange={(e:ChangeEvent<HTMLInputElement>) => this.handleNewPasswordChanged(e)}
                          placeholder="Enter your new password"
                        />
                        {this.state.showPassword ? (
                          <VisibilityOffOutlinedIcon
                            style={StyleSheet.EyePassward as CSSProperties}
                            onClick={this.toggleVisibility}
                          />
                        ) : (
                          <VisibilityOutlinedIcon
                            style={StyleSheet.EyePassward as CSSProperties}
                            onClick={this.toggleVisibility}
                          />
                        )}
                      </Box>
                      <Box style={{position:"relative"}}>
                        <label style={StyleSheet.inputLabel}>Confirm New Password</label>
                        <input
                          type="text"
                          name="confirmPassword"
                          style={{...StyleSheet.InputText , border:this.errorCheck() ? "1px solid #F87171":"1px solid #CBD5E1"}}
                          value={this.state.showConfirmPassword ? this.state.asteriskConfirm : this.state.originalConfirmPassword}
                          data-test-id="handleConfirmPasswordChange"
                          onChange={(e:ChangeEvent<HTMLInputElement>) => this.handleConfirmPasswordChange(e)}
                          placeholder="Confirm your new password"
                        />
                        {this.state.showConfirmPassword ? (
                          <VisibilityOffOutlinedIcon
                            style={StyleSheet.EyePassward as CSSProperties}
                            onClick={this.toggleConfirmVisibility}
                          />
                        ) : (
                          <VisibilityOutlinedIcon
                            style={StyleSheet.EyePassward as CSSProperties}
                            onClick={this.toggleConfirmVisibility}
                          />
                        )}
                        <p style={{ color: "#DC2626", margin: "0px" }}>{this.errorCheck() && "Password don’t match."}</p>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} style={StyleSheet.validation as CSSProperties}>
                    <Box style={{ display: "flex", justifyContent: "right" }}>
                      <Box style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
                        <p style={{ fontWeight: 600, margin: 0 }}>Your new password must contain</p>
                        <Box>{this.state.requirements.upperLower ? <img src={success} width={"12px"} height={"12px"} /> : <img src={crossSigin} width={"12px"} height={"12px"} />}  <p style={{ display: "inline-block", fontSize: "12px", margin: "0px" }}>At least one capital letter and lowercase letter</p></Box>
                        <Box>{this.state.requirements.specialChar ? <img src={success} width={"12px"} height={"12px"} /> : <img src={crossSigin} width={"12px"} height={"12px"} />}  <p style={{ display: "inline-block", fontSize: "12px", margin: "0px" }}>At least one special character</p></Box>
                        <Box>{this.state.requirements.number ? <img src={success} width={"12px"} height={"12px"} /> : <img src={crossSigin} width={"12px"} height={"12px"} />}  <p style={{ display: "inline-block", fontSize: "12px", margin: "0px" }}>At least one number</p></Box>
                        <Box>{this.state.requirements.length ? <img src={success} width={"12px"} height={"12px"} /> : <img src={crossSigin} width={"12px"} height={"12px"} />}  <p style={{ display: "inline-block", fontSize: "12px", margin: "0px" }}>Minimum character length is 8 characters</p></Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        :
        <Box component="div" style={StyleSheet.ContainerBox}>
          <Button
            data-test-id="save-changes-button"
            style={StyleSheet.BackBtn as React.CSSProperties}
            onClick={() => { this.emailChangeBackBtn() }}
          >
            <span style={{ fontSize: "20px" }}> &#60; </span>&nbsp; Back
          </Button>
          <Box style={{ padding: "30px 32px", backgroundColor: "white" }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <h2 style={{ margin: "0px", fontSize: "18px", color: "#0F172A" }}>Change Email</h2>
                <p style={{ margin: "0px", fontSize: "14px", color: "#475569" }}>Please fill in below details to change your email address.</p>
              </Grid>
              <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
                <Button
                  onClick={this.resetAllEmail}
                  style={StyleSheet.cancelBtn as React.CSSProperties}>
                  Cancel
                </Button>
                <Button
                  data-test-id="saveEmailChange"
                  style={!this.desiableEmailCheck() ? StyleSheet.saveChanges as React.CSSProperties : StyleSheet.saveChangesDisabled as React.CSSProperties}
                  disabled={this.desiableEmailCheck()}
                  onClick={this.saveEmailChange}
                >
                  Save Changes
                </Button>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <Grid container style={StyleSheet.rowReverse as React.CSSProperties}>
                  <Grid item xs={12} md={5}>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                      <Box style={{ position: "relative" }}>
                        <label style={StyleSheet.inputLabel}>Current Email</label>
                        <input type="text" name="newPassword"
                          style={{ ...StyleSheet.InputText, border: this.state.emailError.current_email ? "1px solid #F87171" : "1px solid #CBD5E1" }}
                          value={this.state.email.current_email}
                          data-test-id="handleChangeCurrentEmail"
                          onChange={(e) => this.handleChangeEmail("current_email", e.target.value)}
                          placeholder="Enter your current email"
                        />
                        {this.state.emailError.current_email && <p style={{ color: "#DC2626", margin: "0px" }}>{this.state.emailError.current_email}</p>}
                      </Box>
                      <Box style={{ position: "relative" }}>
                        <label style={StyleSheet.inputLabel}>New Email</label>
                        <input
                          type="text"
                          name="confirmPassword"
                          style={{ ...StyleSheet.InputText, border: this.state.emailError.new_email ? "1px solid #F87171" : "1px solid #CBD5E1" }}
                          value={this.state.email.new_email}
                          data-test-id="handleChangeNewEmail"
                          onChange={(e) => this.handleChangeEmail("new_email", e.target.value)}
                          placeholder="Enter your new email"
                        />
                        {this.state.emailError.new_email && <p style={{ color: "#DC2626", margin: "0px" }}>{this.state.emailError.new_email}</p>}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>}
        <Modal
          open={this.state.popUpToggle}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={StyleSheet.modalPaper as React.CSSProperties}>
            <Box style={StyleSheet.modelHeadingBox}>
            <h2 style={StyleSheet.modelHeading}>Confirm Your Identity</h2>
            <img src={black_cross_sigin} width={"20px"} height={"20px"} onClick={this.cancelPasswordPopUpToggle}/>
            </Box>
            <p style={ StyleSheet.ConfirmationText }>
              To change your email, please enter your current password to confirm your identity.
            </p>
            <Box style={{ position: "relative" }}>
              <label style={{...StyleSheet.inputLabel, color: "#475569" }}> Current Password </label>
              <input type="text" name="password"
                style={{ ...StyleSheet.InputText, border: this.state.emailError.new_email ? "1px solid #F87171" : "1px solid #CBD5E1" }}
                value={this.state.showEmailCurrentPassword ? this.state.asteriskEmailCurrent : this.state.email.password}
                data-test-id="handleEmailCurrentPasswordChange"
                onChange={(e: ChangeEvent<HTMLInputElement>) => this.handleEmailCurrentPasswordChange(e)}
                placeholder="Enter your current password"
              />
              {this.state.showEmailCurrentPassword ? (
                <VisibilityOffOutlinedIcon
                  data-test-id="IconShowCurrentPassword"
                  style={StyleSheet.EyePassward as CSSProperties}
                  onClick={this.toggleEmailCurrentVisibility}
                />
              ) : (
                <VisibilityOutlinedIcon
                  data-test-id="IconHideCurrentPassword"
                  style={StyleSheet.EyePassward as CSSProperties}
                  onClick={this.toggleEmailCurrentVisibility}
                />
              )}
              <p style={{ color: "#DC2626", margin: "0px" }}>{ this.state.emailError.password }</p>
            </Box>
            <div style={StyleSheet.footerBtnGroup }>
              <Button
                onClick={this.cancelPasswordPopUpToggle}
                style={{ ...StyleSheet.cancelBtn, backgroundColor: "#E0EDF0" }as React.CSSProperties}
                data-test-id="CancelPage"
              >
                Cancel
              </Button>
              <Button
                data-test-id="saveEmailPassword"
                onClick={() => this.saveEmailPassword()} 
                style={!this.desiableCurrentEmailPasswordCheck() ? StyleSheet.saveChanges as React.CSSProperties : StyleSheet.saveChangesDisabled as React.CSSProperties}
                >
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.state.passwordWarningToggle}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={StyleSheet.modalPaper as React.CSSProperties}>
            <h2 id="simple-modal-title">Warning</h2>
            <p id="simple-modal-description">
              Would you like to go to the next page without saving?
            </p>
            <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
              <Button
                onClick={() => this.cancelPasswordChange()}
                style={StyleSheet.cancelBtn as React.CSSProperties}
                data-test-id="CancelPasswordModel"
              >
                Cancel
              </Button>
              <Button data-test-id="ContinueAndChangePage" onClick={() => this.continuePageChanges()} style={StyleSheet.saveChanges as React.CSSProperties}>
                Continue
              </Button>
            </div>
          </div>
        </Modal>
        {this.displayDataErrorMessage()}
        {this.displayApiErrorMessage()}
      </MyProfileHeader>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyleSheet = {
  ContainerBox: {
    padding: "24px 48px 24px 48px",
    fontFamily: "Poppins"
  },
  EyePassward: {
    cursor: 'pointer',
    color: '#94A3B8',
    position: "absolute",
    right: "18px",
    top: "45px"
  },
  inputLabel: {
    display: "block",
    fontSize: "16px",
    fontWeight: 600,
    color: "#64748B",
    fontFamily:"Poppins"
  },
  InputText: {
    display: "block",
    padding: "10px 8px 10px 8px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    width: "100%",
    height: "56px",
    marginTop: "4px",
    fontFamily: "Poppins",
    outline: "none",
    backgroundColor: "white"
  },
  cancelBtn: {
    color: "#237182",
    fontWeight: 600,
    textTransform: "capitalize",
    fontFamily: "Poppins",
    padding: "10px 16px"
  },
  saveChanges: {
    color: "white",
    fontWeight: 600,
    backgroundColor: "#237182",
    textTransform: "capitalize",
    fontFamily: "Poppins",
    marginLeft: "24px"
  },
  BackBtn: {
    color: '#334155',
    fontFamily: "Poppins",
    padding: "10px 16px",
    marginBottom: "24px",
    textTransform: "capitalize",
    fontSize: "14px",
    fontWeight: 600
  },
  saveChangesDisabled: {
    color: '#64748B',
    backgroundColor: '#F1F5F9',
    fontWeight: 600,
    textTransform: "capitalize",
    fontFamily: "Poppins",
    marginLeft: "24px",
    padding: "10px 16px"
  },
  rowReverse: {
    ...(window.innerWidth < 600 && {
      display: "flex",
      flexDirection: "column-reverse",
      gap: "25px"
    })
  },
  validation: {
    ...(window.innerWidth < 600 && {
      display: "flex",
      justifyContent: "left",
      alignContent: "center"
    })
  },
  modalPaper: {
    position: 'absolute',
    width: 400,
    backgroundColor: "#fff",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    borderRadius: "16px",
    border: "none",
    padding: "40px 32px 40px 32px"
  },
  modelHeading:{
    fontWeight: 700,
    fontFamily: "Poppins",
    color:"#0F172A",
    margin:"0px",
  },
  modelHeadingBox:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    marginBottom:"12px"
  },
  ConfirmationText:{
    color:"#1E293B", 
    fontFamily: "Poppins",
    margin: "0px 0px 24px 0px"
  },
  footerBtnGroup:{
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "32px"
  },
  modelCurrentPassword:{ 
    fontSize:"14px", 
    color: "#475569",
    fontFamily:"Poppins",
    fontWeight: 600
  }
}
// Customizable Area End
