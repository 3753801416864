import React from "react";
// Customizable Area Start
import {
  Button,
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const loginbg = require("../assets/loginbg.jpg");
import { Link } from 'react-router-dom';
import { slider2, backicon } from "./assets";
import "react-datepicker/dist/react-datepicker.css";
const webStyles = {
  textColor: {
    color: "#334155",
    fontSize: "16px",
    fontWeight: "400"
  } as const,
  mainbox: {
    display: 'flex',
    height: '100vh',
    padding: 0,
    alignItems: "stretch",
    justifyContent: "stretch",
    backgroundColor: "rgba(50, 89, 98, 1)",
  },
  linktext: {
    fontSize: "18px",
    color: "#237182"
  },
  rightBox: {
    backgroundColor: "#ffffff",
    borderTopLeftRadius: "24px",
    borderBottomLeftRadius: "24px",
    padding: "48px",
    height: "100vh",
    overflowY: "auto",
    width: "50%",
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
  } as const,
  msgText: {
    fontWeight: 700,
    fontFamily:"Poppins",
    lineHeight: "58px",
    color: "white",
    fontSize: "44px",
  },
  leftbox: {
    padding: "48px",
    height: "100vh",
    width: "50%",
    display: 'flex',
    alignItems: "center",
    justifyContent: 'center',
    boxSizing: "border-box",
    textAlign: "center",
  } as const,
  signupText: {
    ontWeight: "bold",
    textAlign: "center",
    color: "black",
    fontSize: "30px",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  smallText: {
    fontSize: "18px",
    color: "white",
    width: "99%",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 400,
    marginTop: "9px",
    textAlign: "center",
  } as const,
  textStyle: {
    color: "#334155",
    fontSize: "16px",
    fontWeight: "600"
  },
  errormessage: {
    color: "#334155",
    fontSize: "14px"
  },
  dontText: {
    fontSize: "16px",
    textAlign: "center",
  } as const,

  imgPic: {
    width: "500px",
  },
  validationText: {
    gap: '5px',
    display: "flex",
    alignItems: "center"
  },
  privacytext: {
    color: '#237182',
    fontSize: '14px',
    fontWeight: 400,
    cursor: 'pointer'
  },
  signuptext: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "30px",
  } as const,
  signupTextStyle: {
    marginTop: '10px',
    background: "#237182",
    borderRadius: "8px",
    fontWeight: "bold",
    color: "white",
    fontSize: "16px",
    textTransform: 'none',
  } as const,
  textspan: {
    fontWeight: "700",
    fontSize: "18px",
    color: "#237182"
  },
  checkBoxText: {
    marginLeft: "-9px",
    display: "flex",
    alignItems: "center"
  },
  agreeTextSty: {
    fontWeight: "400",
    fontSize: "14px",
  },
  privacyPolicyText: {
    fontSize: '24px',
    fontWeight: 700
  },
  iconButtonStyle: {
    position: 'absolute',
    right: ' 10px',
    top: '10px',
  } as const,
  logohereText: {
    color: "white",
    fontSize: "30px",
    fontWeight: "bold",
    textAlign: 'left',
  } as const,
  closeIconSty: {
    color: "#0F172A",
    height: "24px",
    width: "24px"
  } as const
};
const styles = {
  customArrowIcon: {
    width: '12px',
    height: '8px'
  } as const,
  textlabel2: {
    lineHeight: '24px',
    fontWeight: 600,
    minWidth: '41px',
    fontSize: '16px',
    color: '#237182',
    fontFamily: 'Poppins, sans-serif',
    marginLeft: '8px',
    userSelect: "none"
  } as const,
  link: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    userSelect: "none"
  } as const,
  groupSection1: {
    display: "flex",
    alignItems: "center",
  },
  group1: {
    alignSelf: "stretch",
    maxWidth: "100%",
    gap: "51px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%"
  } as const,
  group2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
    flexDirection: "column",
  } as const,
  groupTop: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "24px",
  } as const,

  group3: {

    padding: "0 7px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%"
  } as const,
  groupStep: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    boxSizing: "border-box",
    padding: "8px 25px",
    minWidth: "195px",
  } as const,
   
  titleLabel: {
    textAlign: "center",
    alignSelf: "stretch",
    position: "relative",
    fontSize: "30px",
    margin: 0,
    fontFamily: "'Poppins', sans-serif",
    lineHeight: "40px",
    fontWeight: "bold",
    letterSpacing: "normal",
    
  } as const,
  groupStep1: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    gap: "16px",
    overflow: "hidden",
  } as const,
  groupActiveStep: {
    height: "32px",
    width: "32px",
    borderRadius: "50%",
    backgroundColor: "#6be578",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  } as const,
  imageIcon: {
    width: "100%",
    height: "auto",
    borderRadius: "50%",
    objectFit: "cover",
  } as const,
  stepTitle: {
    textDecoration: "none",
    position: "relative",
    lineHeight: "24px",
    fontWeight: 600,
    color: "inherit",
    fontFamily: "Poppins, sans-serif",
    letterSpacing: "normal",
  } as const,
  groupStep2: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "8px 25px",
    boxSizing: "border-box",
    minWidth: "195px",
    color: "#ffffff",
  } as const,
  groupStep3: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    gap: "16px",
    overflow: "hidden",
  } as const,
  groupActiveStep1: {
    height: "32px",
    width: "32px",
    borderRadius: "90px",
    backgroundColor: "#1F2937",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "4px 11px",
    boxSizing: "border-box",
  } as const,
  textlabel: {
    textDecoration: "none",
    position: "relative",
    lineHeight: "24px",
    fontWeight: 600,
    color: "inherit",
    display: "inline-block",
    minWidth: "10px",
    fontFamily: "'Poppins', sans-serif",
  } as const,
  textlabelTitle: {
    fontFamily: "'Poppins', sans-serif",
    textDecoration: "none",
    fontWeight: 600,
    position: "relative",
    lineHeight: "24px",
    color: "#1F2937", 
  } as const,
  group4: {
    marginTop: "9px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
    gap: "24px",
    maxWidth: "100%", 
  } as const,
  groupContent: {
    alignSelf: "stretch",
    display: "flex",
    fontSize: "inherit",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "16px",
    textAlign: "left",
    flexDirection: "column",
    color: "#475569",
  } as const,

  group43: {
    marginTop: "-20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    justifyContent: "center",
    gap: "24px",
    maxWidth: "100%",
   
  } as const,
  groupInputWithLabel: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flex: 1,
  } as const,
  inputName: {
    fontWeight: 600,
    position: "relative",
    lineHeight: "24px",
    fontFamily: "Poppins, sans-serif",
  } as const,
  textinputBoxed: {
    height: "56px",
    color: "#94A3B8",
    backgroundColor: " #FFFFFF",
    borderRadius: "8px",
    fontFamily: "Poppins, sans-serif",
    width: "100%",
  } as const,
  menu: {
    color: "#94A3B8",
    fontFamily: "Poppins, sans-serif",
  } as const,
  groupInputWithLabel1: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "8px",
  } as const,
  textlabel1: {
    position: "relative",
    lineHeight: "24px",
    fontWeight: 600,
    display: "inline-block",
    minWidth: "33px",
  } as const,
  textinputBoxed1: {
    border: 0,
    backgroundColor: "transparent",
    alignSelf: "stretch",
    fontFamily: "inherit",
    fontSize: "inherit",
    color: "#94A3B8",
    whiteSpace: "nowrap",
  } as const,
  group5: {
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "16px",
  } as const,
  group6: {
    width: "614px",
    overflowX: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "24px",
    maxWidth: "100%",
  } as const,
  group7: {
    width: "614px",
    overflowX: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "24px",
    maxWidth: "100%",
  } as const,
  group8: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "50px",
    marginBottom: "-50px",
    width: "100%"
  } as const,
  textbutton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    paddingRight: "135px",
    gap: "8px",
    minWidth: "164px",
  } as const,
  imageIcon1: {
    height: "20px",
    width: "20px",
    position: "relative",
  } as const,

  button: {
    width: "50%",
    textTransform: "none",
    color: "#64748b",
    fontSize: "16px",
    height: "51px",
    background: "#E4E7EA",
    borderRadius: "8px",
    fontWeight: "600",
    cursor: 'pointer',
    gap: "4px",
    fontFamily: "Poppins, sans-serif",
    "&:hover": { background: "#f1f5f9" },
  } as const,
  noFocusOutline: {
    '&:focus': {
      outline: 'none',
      borderColor: 'initial', // Or any other color you want
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'initial', // Or any other color you want
    },
  } as const,
  buttonwith: {
    minWidth: "270px",
    width: "100px",
    textTransform: "none",
    color: "white",
    fontSize: "16px",
    height: "51px",
    background: "#237182",
    borderRadius: "8px",
    fontWeight: "600",
    cursor: 'pointer',
    fontFamily: "Poppins, sans-serif",
    "&:hover": { background: "#f1f5f9" },
  } as const,
  customIcon: {
    color: "blue",
    fontSize: "24px",
  } as const,
};
const errorMessageStyles = {
  color: 'red',
  fontSize: '12px',
  fontWeight: 500,
  marginLeft: '0rem',
  marginRight: "0rem",
  marginTop: '4px',
  fontFamily: "'Poppins', sans-serif",
};
const inputStyles = {
  borderRadius: "8px",
  color: "rgb(53, 64, 79)",
  fontFamily: "Poppins, sans-serif",
};



// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #CBD5E1'
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #CBD5E1'
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,   // Extra-small devices (phones, 600px and down)
      sm: 600, // Small devices (tablets, 600px to 960px)
      md: 960, // Medium devices (desktops, 960px to 1280px)
      lg: 1280,// Large devices (large desktops, 1280px to 1920px)
      xl: 1920,// Extra-large devices (larger desktops, 1920px and up)
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  configJSON
} from "./CustomisableUserProfilesController";
import { Country, State } from "country-state-city";
// Customizable Area End

export default class CustomisableBusinessProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { company_number, vat, street_name, city, zip_code } = this.state;
    const { country, state, companyNumberErrorMsg, companyNumberError, stateError, countryError, streetNameError, streetNameErrorMsg, cityError, cityErrorMsg, zipcodeError, zipcodeErrorMsg } = this.state;
    const states = country
      ? State.getStatesOfCountry(country).map(state => ({
        isoCode: state.isoCode,
        name: state.name
      }))
      : [];
    const formComplete1 = Boolean(
      company_number.trim() !== '' &&
      vat.trim() !== '' &&
      street_name.trim() !== '' &&
      city.trim() !== '' &&
      zip_code.trim() !== '' &&
      state && state.trim() !== '' &&
      country && country.trim() !== ''
    );
    return (
      <ThemeProvider theme={theme}>
        {/* <Container maxWidth='md'> */}
        {/* Customizable Area Start */}

        <Grid container style={webStyles.mainbox}>
          <Grid item lg={6} sm={12} xs={12}
            style={{
              display: 'flex',
              alignItems: "center",
              justifyContent: 'center',
              boxSizing: "border-box",
              textAlign: "center",
              padding: "48px",
              height: "100vh",
              width: "50%",
              backgroundImage: `url(${loginbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Box>
              <Typography style={webStyles.logohereText}>
                Logo Here
              </Typography>
              <img src={slider2} style={webStyles.imgPic} />
              <Box paddingX={5}>
                <Typography style={webStyles.msgText}>
                  Your new construction <br/> management tool.
                </Typography>
                <Typography style={webStyles.smallText}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non faucibus nunc. Pellentesque.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} sm={12} xs={12} data-test-id='oKay' style={webStyles.rightBox} >
            <Box style={styles.groupSection1}>
              <Box style={styles.group1}>
                <Box style={styles.groupTop}>
                  <Box style={styles.group2}>
                    <Typography style={styles.titleLabel}>
                      Create your profile
                    </Typography>
                    <Box style={styles.group3}>
                      <Box style={styles.groupStep}>

                        <Box style={styles.groupStep1}>
                          <Box style={styles.groupActiveStep}>
                            <img
                              style={styles.imageIcon}
                              alt=""
                              src="https://cdn.pixabay.com/photo/2017/01/13/01/22/ok-1976099_640.png"
                            />
                          </Box>
                          <Typography style={styles.textlabelTitle}>
                            Personal Information
                          </Typography>
                        </Box>
                      </Box>
                      <Box style={styles.groupStep2}>

                        <Box style={styles.groupStep3}>
                          <Box style={styles.groupActiveStep1}>
                            <Typography style={styles.textlabel}>2</Typography>
                          </Box>
                          <Typography style={styles.textlabelTitle}>
                            Business Information
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box style={styles.groupContent} component="form">
                  <Box style={styles.group43}>
                    <Box style={styles.groupInputWithLabel}>
                      <Typography style={styles.inputName}>
                        Company Number *
                      </Typography>
                      <TextField
                        style={styles.textinputBoxed}
                        placeholder="Enter company number"
                        variant="outlined"
                        name="company_number"
                        data-test-id="txtInputComapnyNumber"
                        value={this.state.company_number}
                        onChange={this.updateCompanyNumber}
                        error={companyNumberError}
                        helperText={this.getHelperText(companyNumberError, companyNumberErrorMsg)}
                        InputProps={{ style: inputStyles }}
                        FormHelperTextProps={{ style: errorMessageStyles }}
                      />
                    </Box>

                    <Box style={styles.groupInputWithLabel}>
                      <Typography style={styles.inputName}>VAT *</Typography>
                      <TextField
                        style={styles.textinputBoxed}
                        placeholder="Enter VAT"
                        variant="outlined"
                        name="vat"
                        data-test-id="txtInputvat"
                        value={this.state.vat}
                        onChange={this.updateVAT}
                        error={this.state.vatError}  // Corrected here to use state
                        helperText={this.getHelperText(this.state.vatError, this.state.vatErrorMsg)}  // Corrected here to use state
                        InputProps={{ style: inputStyles }}
                        FormHelperTextProps={{ style: errorMessageStyles }}
                      />
                    </Box>
                  </Box>
                  <Box style={styles.group4}>
                    <Box style={styles.groupInputWithLabel}>
                      <Typography style={styles.inputName}>
                        Street Name *
                      </Typography>
                      <TextField
                        style={styles.textinputBoxed}

                        placeholder="Enter street name"
                        variant="outlined"
                        name="street_name"
                        data-test-id="txtInputStreetName"
                        value={this.state.street_name}
                        onChange={this.updateStreetName}
                        error={streetNameError}
                        helperText={this.getHelperText(streetNameError, streetNameErrorMsg)}
                        InputProps={{ style: inputStyles }}
                        FormHelperTextProps={{ style: errorMessageStyles }}
                      />
                    </Box>
                  </Box>
                  <Box style={styles.group4}>
                    <Box style={styles.groupInputWithLabel}>
                      <Typography style={styles.inputName}>City *</Typography>
                      <TextField
                        style={styles.textinputBoxed}
                        placeholder="Enter city"
                        variant="outlined"
                        name="city"
                        data-test-id="txtInputcityName"
                        value={this.state.city}
                        onChange={this.updateCity}
                        error={cityError}
                        helperText={this.getHelperText(cityError, cityErrorMsg)}
                        InputProps={{ style: inputStyles }}
                        FormHelperTextProps={{ style: errorMessageStyles }}
                      />

                    </Box>
                    <Box style={styles.groupInputWithLabel}>
                      <Typography style={styles.inputName}>Zip code *</Typography>
                      <TextField
                        style={styles.textinputBoxed}
                        placeholder="Enter zip code"
                        variant="outlined"
                        name="zip_code"
                        data-test-id="txtInputZipCode"
                        value={this.state.zip_code}
                        onChange={this.updateZipCode}
                        error={zipcodeError}
                        helperText={this.getHelperText(zipcodeError, zipcodeErrorMsg)}
                        InputProps={{ style: inputStyles }}
                        FormHelperTextProps={{ style: errorMessageStyles }}
                      />

                    </Box>
                  </Box>
                  <Box style={styles.group4}>
                    <Box style={styles.groupInputWithLabel}>
                      <Typography style={styles.inputName}>State *</Typography>
                      <Select
                        style={{
                          height: "56px",
                          color: this.state.state ? "#000000" : "#94A3B8",
                          backgroundColor: this.state.state ? "#FFFFFF" : "#FCFDFE",
                          borderRadius: "8px",
                          fontFamily: "Poppins, sans-serif",
                          width: "100%",
                        }}
                        placeholder="Enter state"
                        variant="outlined"
                        name="state"
                        data-test-id="txtInputState"
                        value={this.state.state}
                        onChange={this.handleStateChange}
                        error={stateError}
                        displayEmpty
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              position: "fixed",
                              height: "300px",
                              width: "20px",
                              right: "500px",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">Select state</MenuItem>
                        {
                          states.map((state, index) => (
                            <MenuItem key={index} value={state.isoCode}>
                              {state.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Box>
                    <Box style={styles.groupInputWithLabel}>
                      <Typography style={styles.inputName}>Country *</Typography>
                      <Select
                        style={{
                          height: "56px",
                          color: this.state.country ? "#000000" : "#94A3B8",
                          backgroundColor: this.state.country ? "#FFFFFF" : "#FCFDFE",
                          borderRadius: "8px",
                          fontFamily: "Poppins, sans-serif",
                          width: "100%",
                        }}
                        value={this.state.country}
                        onChange={this.handleCountryChange}
                        placeholder="Select Country"
                        variant="outlined"
                        name="country"
                        data-test-id="txtInputCountry"
                        error={countryError}
                        displayEmpty
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              position: "fixed",
                              height: "300px",
                              width: "20px",
                              right: "500px",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">Select country</MenuItem>
                        {Country.getAllCountries().map((country, index) => (
                          <MenuItem key={index} value={country.isoCode}>
                            {country.name}
                          </MenuItem>
                        ))}

                      </Select>
                    </Box>
                  </Box>
                  <Box style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "6rem"
                  }}
                  sx={{
                    width: '100%',
                  }
                  }>
                    <Link to="/CustomisablePersonalProfiles" style={styles.link}>
                      <img src={backicon} alt="Back Icon" />
                      <div style={styles.textlabel2}>Back</div>
                    </Link>
                    <Button
                      data-test-id="submitBtn"
                      type="button"
                      onClick={this.handleSubmit}
                      style={formComplete1 ? styles.buttonwith : styles.button}
                      disabled={!formComplete1}
                      fullWidth
                    >
                      Next
                    </Button>
                  </Box>
                </Box>

              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Customizable End Start */}
        {/* </Container> */}
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
