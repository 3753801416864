Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
  exports.countryCodes = [
    { code: 'GB', name: 'United Kingdom', dial_code: '+44' },
    { code: 'CA', name: 'Canada', dial_code: '+123' },
    { code: 'DE', name: 'Germany', dial_code: '+49' },
    { code: 'ES', name: 'Spain', dial_code: '+34' },
    { code: 'PRT', name: 'Portugal', dial_code: '+351' },
    { code: 'RU', name: 'Russia', dial_code: '+7' },
    { code: 'CHN', name: 'China', dial_code: '+86' },
    { code: 'SA', name: 'Saudi Arabia', dial_code: '+966' },
  ];
// Customizable Area End
