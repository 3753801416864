import React from "react";

// Customizable Area Start
import {
  styled, Box
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { Logo, backgroundImage, mail, phone } from "./assets";
import ToastMessage from './ToastMessage.web';
const MainBox = styled(Box)({
  fontFamily: "'Poppins', sans-serif",
  fontLine:"24px",
  fontWeight:600,
  display: "flex",
  height: "100vh",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  overflowY:"hidden",
  "@media (max-width: 768px)": {
    height:'auto',
    overflowY:'scroll',
    flexDirection:'column'
  },
})
const LeftBox = styled(Box)({
  flex: 1,
  position: "relative",
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  "@media (max-width: 768px)": {
    padding: '20px'
  },
})
const RightBox = styled(Box)({
  flex: 1,
  padding: "20px",
  display: "flex",
  alignItems: "center",
  width: "100%",
  borderBottomLeftRadius: "20px",
  borderTopLeftRadius: "20px",
  backgroundColor: "white",
  "@media (max-width: 768px)": {
    width: "auto",
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    display: "block"
  },
})
const BackDiv =styled("div")({
    cursor:"pointer",
    position:'fixed',
    left:"51%",
    top:'25px',
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    " & p":{
        fontWeight:600,
        fontSize:'16px',
        fontFamily:"'Poppins', sans-serif",
        color:'#0F172A'
    },
  "@media (max-width: 768px)":{
    left: 0,
    position:'relative',
    top: 0
  }
})
const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "90%",
  margin: "0 auto",
  "@media (max-width: 768px)": {
    width: "100%",
    margin: 0,
  },
})
const InputContainer = styled("div")({
  marginBottom: "15px",
  width: "100%",
})
const LabelForInput = styled("label")({
  display: "block",
  marginBottom: "5px",
  fontWeight:600,
  color:'#0F172A',
  fontSize:'16px',
  fontFamily:"'Poppins', sans-serif",
})
const OptionsWrapper = styled("span")({
    width:'50%',
    border: "1px solid #CBD5E1",
    height: "58px",
    borderRadius: "8px",
    alignItems:"center",
    display:'flex',
    "& span":{
        marginTop:'5px',
    },
    " & span p": {
        fontFamily: "'Poppins', sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#0F172A",
        marginBlock: "-5px"
      },
      " & span span": {
        fontFamily: "'Poppins', sans-serif",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "26px",
        color: "#475569"
      }
})
const SubmitButton = styled("button")({
  width: "100%",
  padding: "16px",
  border: "none",
  cursor: "pointer",
  color: "#FFFFFF",
  background: "#237182",
  borderRadius: "8px",
  fontWeight: 600,
  fontSize: "16px",
  textTransform: 'none',
  fontFamily:"'Poppins', sans-serif",
})
const ContactusLink = styled("div")({
  textAlign: "center",
  marginTop: "20px",
  fontFamily: "'Poppins', sans-serif",
  color: "rgba(15, 23, 42, 1)",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px",
  " & a": {
    fontFamilt: "'Poppins', sans-serif",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    color: "#237182",
    textDecoration: "none",
  }
})
const LogoDiv = styled("div")({
  position: "absolute",
  width: "90%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 768px)": {
    position:'inherit'
  },
});
const LogoPic = styled("img")({
  width: "70%",
  height:'70%',
  objectFit:'contain',
  "@media (max-width: 1440px)": {
    width: "79.5%",
    height:'79.5%'
  },
});
const LogoTitle = styled("div")({
  color: "#fff",
  fontSize: "30px",
  fontWeight: 700,
  width: "100%",
  fontFamily:"'Poppins', sans-serif",
  letterSpacing: '-0.5px'
});
const SubTitle = styled("div")({
  textAlign: "center",
  font: "poppins",
  fontWeight: 700,
  fontSize: "48px",
  color: "white",
  lineHeight: "1.2",
  letterSpacing: '-3px',
  fontFamily:"'Poppins', sans-serif",
  "@media (max-width: 768px)": {
    fontSize: "24px",
  },
});
const TextForLogo2 = styled("div")({
  textAlign: "center",
  font: "poppins",
  fontWeight: 400,
  fontSize: "18px",
  color: "white",
  fontFamily:"'Poppins', sans-serif",
})
const TextWrapper = styled("div")({
  paddingLeft: "11px",
  paddingRight: "12px"
})
// Customizable Area End

import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController";

export default class ForgotPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <MainBox style={{
        backgroundImage: `url(${backgroundImage})`,
      }}>
        <LeftBox>
          <LogoDiv>
            <LogoTitle>Logo Here</LogoTitle>
            <LogoPic src={Logo} alt="Logo" />
            <TextWrapper>
              <SubTitle>Your new construction management tool.
              </SubTitle>
               <TextForLogo2> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non faucibus nunc. Pellentesque</TextForLogo2>
            </TextWrapper>
          </LogoDiv>
        </LeftBox>
        <RightBox >
        <BackDiv data-test-id='backIcon' onClick={()=>this.goToLogin()}>
        <ChevronLeft fill="#0F172A"/>
        <p>&nbsp;&nbsp;Back</p>
        </BackDiv>
          <FormContainer>
            <div
              style={{
                fontWeight: "bold",
                textAlign: "center",
                color: "#0F172A",
                fontSize: "30px",
                paddingTop: "20px",
                fontFamily:"'Poppins', sans-serif",
              }}
            >Forgot Password?</div>
            <p style={{
                fontSize:'16px',
                fontWeight: 400,
                color: '#475569',
                fontFamily:"'Poppins', sans-serif",
                textAlign: "center",
                marginBlock:'20px'
            }}>No worries! Please choose a method to receive a one-time verification code.</p>
            <InputContainer>
              <LabelForInput htmlFor="email">Please select:</LabelForInput>
              <div style={{display:'flex', gap:'12px'}}>
              <OptionsWrapper>
                <img src={mail} style={{marginInline:"12px"}}/>
                <span>
                    <p>Email</p>
                    <span>{this.hideEmail(this.state.emailValue)}</span>
                </span>
                </OptionsWrapper>
              <OptionsWrapper>
              <img src={phone} style={{marginInline:"10px"}}/>
              <span>
                    <p>Phone Number</p>
                    <span>*** *** *** 789</span>
                </span>
                </OptionsWrapper>
              </div>
            </InputContainer>
            <SubmitButton data-test-id="sendOTPBtn"
            onClick={()=>this.sendOTP()}
            >Send Verification Code</SubmitButton>
            <ContactusLink>Something went wrong? <a href="/">Contact Us</a></ContactusLink>
          </FormContainer>
          <ToastMessage
            open={this.state.showNotification}
            message={this.state.notificationMessage}
            type={this.state.notificationType}
            onClose={this.handleClose}
          />
        </RightBox>
      </MainBox>
      // Customizable Area End
    );
  }
}
