import React from "react";
// Customizable Area Start
import { Button, FormControlLabel, Typography, RadioGroup, Radio, Checkbox, TextField, FormControl, FormLabel, Select, MenuItem, Modal } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import "react-datepicker/dist/react-datepicker.css";
import { configJSON } from "./CustomisableUserProfilesController";
import WorldFlag from 'react-world-flags';
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import MessageBox from "../../../components/src/messageBox";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const EditImg = require("../assets/Edit-Img.png");
const arrowSign = require("../assets/arrow-sign.png");
const deleteIcon = require("../assets/deleteIcon.png");
const photoLogo = require("../assets/photo.png");
const replacePicIcon = require("../assets/replacePicIcon.png");
import { Country, State } from "country-state-city";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import CustomisableUserProfilesController, {
  Props,
  IField
} from "./CustomisableUserProfilesController";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    )
      return "number";
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    )
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.value, field.field_type)
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );

    if (field.field_type === "date" || field.field_type === "datetime")
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(this.state.profile[field.name] as string).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date:Date) => this.changeFormValue(field.name, date, field.field_type)}
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );

    if (field.field_type === "checkbox")
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.checked, field.field_type)
            }
            color='primary'
          />
          {field.title}
        </div>
      );
    if (field.field_type === "boolean")
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(field.name, event.target.value, field.field_type);
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel value={"true"} control={<Radio />} label='True' />
              <FormControlLabel value={"false"} control={<Radio />} label='False' />
            </RadioGroup>
          </FormControl>
        </div>
      );
  };

  render() {
    // Customizable Area Start
    const toggle = this.state.toggleEditPersonalInfoAndBusinessInfo;
    const country = this.state.editPersonalInformation.country;
    const states = country ? State.getStatesOfCountry(country) : [];
    const mappedStates = states.map(state => ({ isoCode: state.isoCode, name: state.name }));    
    return (
    <div onClick={this.handleClickOutsideReplaceRemovePic} data-test-id = "handleClickOutsideReplaceRemovePic">
    <MyProfileHeader navigation={this.props.navigation} showSidebar={true}>
    {this.state.messageToggle && <MessageBox message={this.state.tostifymessage} status={this.state.status}/>}
      <style>
            {`
            .hover-effect:hover {
              background:#E0EDF0;
            }
            .MuiSelect-outlined.MuiSelect-outlined {
              background-color: white;
            }
            ::placeholder {
              font-size: 16px;
            }
            .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline{
              border: 1px solid rgb(203, 213, 225);
            }
            .MuiOutlinedInput-notchedOutline {
              border-color: rgb(203, 213, 225);
            }
            div[class="MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded"]{
              width:120px!important;
            }
          `}
        </style>
      <Box component="div" style={InformationStyle.PersonalInformationBox}>
        <Box component="div" style={InformationStyle.OptionBox}>
          <Box component="div" style={InformationStyle.singleOptionBox}>
            <Box component="div" style={{ ...InformationStyle.PersonalInformationOption, color: toggle ? "#325962":"#64748B", backgroundColor: toggle? "#E0EDF0":"" } as React.CSSProperties } data-test-id="PersonalInformationBox" onClick={() => this.handleChangePage(true)}>Personal Information</Box>
            <Box component="div" style={{ ...InformationStyle.BusinessInformationOption, color: !toggle ? "#325962":"#64748B", backgroundColor: !toggle? "#E0EDF0":"" }  as React.CSSProperties } data-test-id="BusinessInformationBox" onClick={() => this.handleChangePage(false)}>Business Information</Box>
          </Box>
        </Box>
        {this.state.toggleEditPersonalInfoAndBusinessInfo?<>
        <Grid container spacing={2} style={InformationStyle.PersonalInformationDetailBox}>
          <Grid item xs={12} md={2} style={InformationStyle.PersonalInfoImg}>
            <Box style={{...InformationStyle.personalInfoPhoto, backgroundImage:`url(${photoLogo})`} as React.CSSProperties} data-test-id="handleClickInsideReplaceRemovePic" onClick={this.handleClickInsideReplaceRemovePic}>
            {this.state.previewImage && <Box style={InformationStyle.PersonalInfoActualPhoto}><img src={this.state.previewImage} width={"100%"} height={"100%"} style={InformationStyle.photoImg as React.CSSProperties} /></Box>}
            {this.state.editPicToggle && <Box style={InformationStyle.editImg as React.CSSProperties} data-test-id="replaceRemovePicToggle" onClick={()=>{!this.state.editDetailToggle && this.replaceRemovePicToggle() }}><img src={EditImg} /></Box>}
            {!this.state.editPicToggle && <Box style={InformationStyle.editpic as React.CSSProperties} >
              <Box className="hover-effect" style={InformationStyle.replaceRemovePhoto}> 
               <label htmlFor="file-upload" style={{ padding: "9px 20px 9px 16px" ,display:"flex" ,justifyContent:"center", alignContent:"center", gap:"8px" }} ><img src={replacePicIcon} />  Replace Photo </label>
               <input 
                data-test-id="file-change" 
                id="file-upload" type="file" 
                onChange={this.handleFileChange} 
                style={{ display: 'none' }}  />
              </Box>
              <Box className="hover-effect" style={{...InformationStyle.replaceRemovePhoto, padding: "9px 20px 9px 16px"}} data-test-id="removePhoto" onClick={()=>{ this.removePhoto() }}> <img src={deleteIcon} /> Remove photo </Box>
            </Box>}
            </Box>
          </Grid><Grid item xs={12} md={10} ><Grid container><Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={9}>
                    <p style={InformationStyle.PITitle}>Personal Information</p>
                    <p style={InformationStyle.PIDesc}>Manage your personal information including name, email, and phone number.</p>
                  </Grid >
                  <Grid item xs={12} sm={3}>
                    {this.state.editDetailToggle && <p style={InformationStyle.editDetails as React.CSSProperties}>
                      <span style={{ cursor: "pointer" }} data-test-id="edit-detail-text" onClick={this.editDetailsToggle}>Edit Details</span>
                      </p>}
                    {!this.state.editDetailToggle && <Box style={{...InformationStyle.editDetails, ...InformationStyle.cancelSaveBtn} as React.CSSProperties}>
                      <Button onClick={this.cancelEditDetails} data-test-id="cancelEditDetails" style={InformationStyle.cancelBtn as React.CSSProperties}> Cancel </Button>
                      <Button data-test-id="save-changes-button" style={this.desiableCheck() ? InformationStyle.saveChangesDisabled as React.CSSProperties : InformationStyle.saveChanges as React.CSSProperties} onClick={this.editPersonalInformationAPI} disabled={this.desiableCheck()}>
                        Save Changes
                      </Button>                 
                    </Box>}
                  </Grid>
                </Grid>
              </Grid><Grid item xs={12} style={{ marginTop: "24px" }}><Grid container spacing={2}><Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>First Name {!this.state.editDetailToggle && <span>*</span>} </label>
                      <input 
                      type='text'
                      disabled={this.state.editDetailToggle} 
                      placeholder="Enter first name" 
                      style={{...InformationStyle.InputText, border:`1px solid ${this.state.editError.first_name?"#F87171":"#CBD5E1"}`}} 
                      value={this.state.editPersonalInformation.first_name} 
                      data-test-id="handleChangeEdit" 
                      onChange={(e) => this.handleChangeEdit(e.target.value, "first_name")} />
                      <p style={InformationStyle.errorShow}>{this.state.editError.first_name}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Last Name {!this.state.editDetailToggle && <span>*</span>} </label>
                      <input 
                      type='text' 
                      disabled={this.state.editDetailToggle} 
                      placeholder="Enter last name" style={{...InformationStyle.InputText, border:`1px solid ${this.state.editError.last_name?"#F87171":"#CBD5E1"}`}} 
                      value={this.state.editPersonalInformation.last_name} 
                      data-test-id="handleChangeEditlast_name" 
                      onChange={(e) => this.handleChangeEdit(e.target.value, "last_name")} 
                      />
                      <p style={InformationStyle.errorShow}>{this.state.editError.last_name}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Phone Number {!this.state.editDetailToggle && <span>*</span>}</label>
                      <div style={{ display: "flex", gap: "8px", justifyContent: "center", alignItems: "center" }}>
                        <Select style={{ ...InformationStyle.textinputBoxedCountryCodePN, color:"#1E293B", border:"none", width: '120px', height: '55px', marginTop:"4px" }} data-test-id="txtInputCountryCode" variant="outlined" name="country_code" value={`+${this.state.editPersonalInformation.country_code}`} onChange={this.EditCountryCode} displayEmpty disabled={this.state.editDetailToggle}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null, // Ensures the menu opens from the bottom of the Select component
                          PaperProps: {
                            style: {
                              height:"323px",
                              width:"20px",
                            },
                          },
                        }}
                            open={this.state.piToggleDropDown}
                            onOpen={() => this.handleTogglePIDropDown()}
                            onClose={() => this.handleTogglePIDropDown()}
                            IconComponent={() => (
                              <div
                                onClick={(e) => {this.handleTogglePIDropDown();}}
                                style={{ marginRight: "8px" }}
                                data-test-id="txtInputCountryCodeIcon"
                              >
                                {this.state.piToggleDropDown ? (
                                  <KeyboardArrowUpIcon style={{ cursor: "pointer", color:"#64748B" }}/>
                                ) : (
                                  <KeyboardArrowDownIcon style={{ cursor: "pointer", color:"#64748B" }}/>
                                )}
                              </div>
                            )}
                        > 
                          {configJSON.countryCodes.map((country: { dial_code: {} | null | undefined; code: React.Key | null | undefined; }) => (
                            <MenuItem value={country.dial_code as string} key={country.code}>
                            <Box display="flex" alignItems="center">
                            <WorldFlag code={country.code as string} height="18" width="24" style={{ marginLeft: "5px", marginRight: "10px", borderRadius: "1px", marginBottom: "5px" }} />
                            <Typography>&nbsp;{country.dial_code}</Typography>
                            </Box>
                            </MenuItem>
                          ))}
                        </Select>
                        <input 
                        type='text' 
                        disabled={this.state.editDetailToggle} 
                        placeholder="000 0000 0000" 
                        style={{...InformationStyle.InputText, border:`1px solid ${this.state.editError.phone_number?"#F87171":"#CBD5E1"}`}} 
                        value={this.state.editPersonalInformation.phone_number} 
                        data-test-id="handleChangeEditphone_number" 
                        onChange={(e) => this.handleChangeEdit(e.target.value, "phone_number")} 
                        />
                      </div>
                      <p style={InformationStyle.errorShow}>{this.state.editError.phone_number}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Role {!this.state.editDetailToggle && <span>*</span>}</label>
                      <input 
                      type='text' 
                      disabled={this.state.editDetailToggle} 
                      placeholder="Choose your role" 
                      style={{...InformationStyle.InputText, border:`1px solid ${this.state.editError.role_id?"#F87171":"#CBD5E1"}`}} 
                      value={this.state.editPersonalInformation.role_id} 
                      data-test-id="handleChangeEditrole_id" 
                      onChange={(e) => this.handleChangeEdit(e.target.value, "role_id")} 
                      />
                      <p style={InformationStyle.errorShow}>{this.state.editError.role_id}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Job Title {!this.state.editDetailToggle && <span>*</span>}</label>
                      <input 
                      type='text' 
                      disabled={this.state.editDetailToggle} 
                      placeholder="Enter your job title" 
                      style={{...InformationStyle.InputText, border:`1px solid ${this.state.editError.job_title?"#F87171":"#CBD5E1"}`}} 
                      value={this.state.editPersonalInformation.job_title} 
                      data-test-id="handleChangeEditjob_title" 
                      onChange={(e) => this.handleChangeEdit(e.target.value, "job_title")} 
                      />
                      <p style={InformationStyle.errorShow}>{this.state.editError.job_title}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>About Me {!this.state.editDetailToggle && <span>*</span>}</label>
                      <textarea 
                      style={{...InformationStyle.textArea, border:`1px solid ${this.state.editError.about_me?"#F87171":"#CBD5E1"}`} as React.CSSProperties} 
                      placeholder="Write something about yourself" 
                      disabled={this.state.editDetailToggle} 
                      value={this.state.editPersonalInformation.about_me} 
                      data-test-id="handleChangeEditabout_me" 
                      onChange={(e) => this.handleChangeEdit(e.target.value, "about_me")}> 
                      </textarea>
                      <p style={InformationStyle.errorShow}>{this.state.editError.about_me}</p>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ ...InformationStyle.PersonalInformationDetailBox }}>
          <Grid item xs={12}>
           <p style={InformationStyle.accountText}>Account Settings</p>
            <p style={InformationStyle.AccPrefAndsetting}>Manage your account preferences and settings here.</p>
          </Grid>
          <Grid item xs={12}>
            <Box style={InformationStyle.EmailPasswordBox}>
              <p style={InformationStyle.pdEmText} data-test-id="changePassword" onClick={()=>{ this.navigatePage(true)}}>Change Password</p>
              <img src={arrowSign} style={{cursor:"pointer"}} data-test-id="changePasswordSec" onClick={()=>{ this.navigatePage(true)}}/>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box style={InformationStyle.EmailPasswordBox}>
              <p style={InformationStyle.pdEmText} data-test-id="changeEmail" onClick={()=>{this.navigatePage(false)}}>Change Email</p>
              <img src={arrowSign} style={{cursor:"pointer"}} data-test-id="changeEmailSec" onClick={()=>{this.navigatePage(false)}}/>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ ...InformationStyle.PersonalInformationDetailBox, padding: "32px" }}>
          <Grid item xs={12}>
            <img src={deleteIcon} style={{ display: "inline-block", marginBottom: "-5px", marginRight: "8px" }} /><p style={{ display: "inline-block", color: "#DC2626", fontSize: "18px", fontWeight: 700, margin: "0px" }}>Delete Account</p>
            <p style={{ margin: "8px 0px 0px 0px" }}>We'd hate to see you leave, but you can delete your account at any time.</p></Grid>
        </Grid></>
        :
        <Grid container spacing={2} style={InformationStyle.PersonalInformationDetailBox}>
          <Grid item xs={12} md={2} style={InformationStyle.PersonalInfoImg}>
            <Box style={{...InformationStyle.personalInfoPhoto, backgroundImage:`url(${photoLogo})`} as React.CSSProperties} onClick={this.handleClickInsideReplaceRemovePic}>
            {this.state.previewImage && <Box style={InformationStyle.PersonalInfoActualPhoto}><img src={this.state.previewImage} width={"100%"} height={"100%"} style={InformationStyle.photoImg as React.CSSProperties} /></Box>}
            {this.state.editPicToggle && <Box style={InformationStyle.editImg as React.CSSProperties} data-test-id="replaceRemovePicToggle" onClick={()=>{!this.state.editDetailToggle && this.replaceRemovePicToggle() }}><img src={EditImg} /></Box>}
            {!this.state.editPicToggle && <Box style={InformationStyle.editpic as React.CSSProperties} >
              <Box className="hover-effect" style={InformationStyle.replaceRemovePhoto}> 
               <label htmlFor="file-upload" style={{padding: "9px 20px 9px 16px"}} ><img src={replacePicIcon} />  Replace Photo </label>
               <input data-test-id="file-change" id="file-upload" type="file" onChange={this.handleFileChange} style={{ display: 'none' }}  />
              </Box>
              <Box className="hover-effect" style={{...InformationStyle.replaceRemovePhoto, padding: "9px 20px 9px 16px"}} data-test-id="removePhoto" onClick={()=>{ this.removePhoto() }}> 
              <img src={deleteIcon} /> Remove photo </Box>
            </Box>}
            </Box>
          </Grid>
          <Grid item xs={12} md={10} >
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={9}>
                    <p style={InformationStyle.PITitle}>Business Information</p>
                    <p style={InformationStyle.PIDesc}>Manage your business information including company name, billing address etc.</p>
                  </Grid >
                  <Grid item xs={12} sm={3}>
                    {this.state.editDetailToggle && <p style={InformationStyle.editDetails as React.CSSProperties}><span style={{ cursor: "pointer" }} data-test-id="businessEditDetails" onClick={this.editDetailsToggle}>Edit Details</span></p>}
                    {!this.state.editDetailToggle && <Box style={{...InformationStyle.editDetails, ...InformationStyle.cancelSaveBtn} as React.CSSProperties}>
                      <Button onClick={this.cancelEditDetails} style={InformationStyle.cancelBtn as React.CSSProperties}> Cancel </Button>
                      <Button
                        data-test-id="save-changes-button"
                        style={this.desiableCheck() ? InformationStyle.saveChangesDisabled as React.CSSProperties : InformationStyle.saveChanges as React.CSSProperties}
                        onClick={this.editPersonalInformationAPI}
                        disabled={this.desiableCheck()}>
                        Save Changes
                      </Button>                 
                    </Box>}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "24px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Company Name</label>
                      <input type='text' disabled={this.state.editDetailToggle} placeholder="Enter company name" style={{...InformationStyle.InputText, border:`1px solid ${this.state.editError.company_name?"#F87171":"#CBD5E1"}`}} value={this.state.editPersonalInformation.company_name} data-test-id="handleChangeEditcompanyName" onChange={(e) => this.handleChangeEdit(e.target.value, "company_name")} />
                      <p style={InformationStyle.errorShow}>{this.state.editError.company_name}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Company Number</label>
                      <input type='text' disabled={this.state.editDetailToggle} placeholder="Enter company number" style={{...InformationStyle.InputText, border:`1px solid ${this.state.editError.company_number?"#F87171":"#CBD5E1"}`}} value={this.state.editPersonalInformation.company_number} data-test-id="handleChangeEditcompany_number" onChange={(e) => this.handleChangeEdit(e.target.value, "company_number")} />
                      <p style={InformationStyle.errorShow}>{this.state.editError.company_number}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>VAT</label>
                      <input type='text' disabled={this.state.editDetailToggle} placeholder="Enter vat" style={{...InformationStyle.InputText, border:`1px solid ${this.state.editError.vat?"#F87171":"#CBD5E1"}`}} value={this.state.editPersonalInformation.vat} data-test-id="handleChangeEditvat" onChange={(e) => this.handleChangeEdit(e.target.value, "vat")} />
                      <p style={InformationStyle.errorShow}>{this.state.editError.vat}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Street Name</label>
                      <input type='text' disabled={this.state.editDetailToggle} placeholder="Enter street name" style={{...InformationStyle.InputText, border:`1px solid ${this.state.editError.street_name?"#F87171":"#CBD5E1"}`}} value={this.state.editPersonalInformation.street_name} data-test-id="handleChangeEditstreet_name" onChange={(e) => this.handleChangeEdit(e.target.value, "street_name")} />
                      <p style={InformationStyle.errorShow}>{this.state.editError.street_name}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>City</label>
                      <input type='text' disabled={this.state.editDetailToggle} placeholder="Enter city" style={{...InformationStyle.InputText, border:`1px solid ${this.state.editError.city?"#F87171":"#CBD5E1"}`}} value={this.state.editPersonalInformation.city} data-test-id="handleChangeEditcity" onChange={(e) => this.handleChangeEdit(e.target.value, "city")} />
                      <p style={InformationStyle.errorShow}>{this.state.editError.city}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Zip code</label>
                      <input type='text' disabled={this.state.editDetailToggle} placeholder="Enter zip code" style={{...InformationStyle.InputText, border:`1px solid ${this.state.editError.zip_code?"#F87171":"#CBD5E1"}`}} value={this.state.editPersonalInformation.zip_code} data-test-id="handleChangeEditzip_code" onChange={(e) => this.handleChangeEdit(e.target.value, "zip_code")} />                      
                      <p style={InformationStyle.errorShow}>{this.state.editError.zip_code}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>State</label>
                      <Select
                        style={{
                          height: "56px",
                          backgroundColor: "white",
                          borderRadius: "8px",
                          fontFamily: "Poppins, sans-serif",
                          width: "100%",
                          color: "#1E293B"
                        }}
                        placeholder="Enter state"
                        variant="outlined"
                        name="state"
                        data-test-id="handleChangeEditState"
                        value={this.state.editPersonalInformation.state}
                        disabled={this.state.editDetailToggle}
                        onChange={(e) => { this.handleChangeEdit(e.target.value as string, "state")}}
                        // error={stateError}
                        displayEmpty
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              position: "fixed",
                              height: "300px",
                              width: "20px",
                              right: "500px",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">Select state</MenuItem>
                        {
                          mappedStates.map((state, index) => (
                            <MenuItem key={index} value={state.isoCode}>
                              {state.name}
                            </MenuItem>
                          ))}
                      </Select>
                      <p style={InformationStyle.errorShow}>{this.state.editError.state}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box>
                      <label style={InformationStyle.inputLabel}>Country</label>
                            <Select
                              style={{
                                height: "56px",
                                backgroundColor: "white",
                                borderRadius: "8px",
                                fontFamily: "Poppins, sans-serif",
                                width: "100%",
                                color: "#1E293B"
                              }}
                              value={this.state.editPersonalInformation.country}
                              onChange={(e) => { this.handleChangeEdit(e.target.value as string, "country"); } }
                              placeholder="Select Country"
                              variant="outlined"
                              name="country"
                              data-test-id="handleChangeEditCountry"
                              disabled={this.state.editDetailToggle}
                              displayEmpty
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left"
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left"
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                  style: {
                                    position: "fixed",
                                    height: "300px",
                                    width: "20px",
                                    right: "500px",
                                  },
                                },
                              }}
                            >
                              <MenuItem value="">Select country</MenuItem>
                              {Country.getAllCountries().map((country, index) => (
                                <MenuItem key={index} value={country.isoCode}>
                                  {country.name}
                                </MenuItem>
                              ))}

                            </Select>
                      <p style={InformationStyle.errorShow}>{this.state.editError.country}</p>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>}
      </Box>
            <Modal
              open={this.state.popUpToggle}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div style={InformationStyle.modalPaper as React.CSSProperties}>
                <h2 id="simple-modal-title">Warning</h2>
                <p id="simple-modal-description">
                  Would you like to go to the next page without saving?
                </p>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
                  <Button
                    onClick={() => this.popUpToggle()}
                    style={InformationStyle.cancelBtn as React.CSSProperties}
                    data-test-id="CancelPage"
                  >
                    Cancel
                  </Button>
                  <Button data-test-id="ContinueAndChangePage" onClick={() => this.continueChanges(!this.state.toggleEditPersonalInfoAndBusinessInfo)} style={InformationStyle.saveChanges as React.CSSProperties}>
                    Continue
                  </Button>
                </div>
              </div>
            </Modal>
    </MyProfileHeader>
        {displayDataErrorMessage()}
        {displayApiErrorMessage()}</div>);
    // Customizable Area End
  }
}

// Customizable Area Start
const InformationStyle = {
  PersonalInformationBox: {
    padding: "24px 48px 24px 48px",
    fontFamily: "Poppins"
  },
  PersonalInformationOption: {
    padding: "10px 12px 10px 12px",
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontWeight: 600,
    cursor: "pointer",
    ...(window.innerWidth < 600 && {
      textAlign: "center"
    }),
  },
  BusinessInformationOption: {
    padding: "10px 12px 10px 12px",
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontWeight: 600,
    cursor: "pointer",
    lineHeight: "27px",
    margin: "0px",
    ...(window.innerWidth < 600 && {
      textAlign: "center"
    })
  },
  OptionBox: {
    display: "flex",
    justifyContent: "start",
  },
  singleOptionBox: {
    display: "flex",
    backgroundColor: "white",
    borderRadius: "50px",
    padding: "4px"
  },
  personInfoDetailBox: {
    display: "flex",
    gap: "24px"
  },
  personalInfoPhoto: {
    width: "104px",
    height: "104px",
    borderRadius: "50%",
    position: "relative",
    border: "3.15px solid #E0EDF0",
    backgroundColor: "#D3E4E9", 
    backgroundRepeat:"no-repeat",
    backgroundPosition: "center"
  },
  PersonalInfoImg: {
    display: "flex",
    justifyContent: "center"
  },
  editDetails: {
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "right",
    color: "#237182",
    marginTop: "0px"
  },
  editImg: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    height: "32px",
    width: "32px",
    display: "flex",
    placeContent: "center",
    placeItems: "center",
    backgroundColor: "#E0EDF0",
    borderRadius: "8px"
  },
  editpic:{
    backgroundColor: "white",
    position: "absolute",
    top: "80px",
    left: "100px",
    cursor: "pointer",
    boxShadow: "0px 2px 8px 0px #00000014",
    padding: "4px",
    ...(window.innerWidth < 600 && {
    top:"115px",
    left:"20px"
    })
  },
  errorLabel: {
    fontWeight: 400,
    fontFamily: '"Poppins", sans-serif',
    letterSpacing: '0.00938em',
    lineHeight: 1.5,
    color: 'red',
    margin: '5px 3px',
    fontSize: '14px',
    userSelect: 'none',
  },
  inputLabel: {
    display: "block",
    fontSize: "16px",
    fontWeight: 600,
    color: "#64748B"
  },
  PITitle: {
    fontWeight: 700,
    margin: "0px",
    fontSize: "18px",
    lineHeight: "26px",
    color: "#0F172A"
  },
  textArea: {
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    padding: "10px 8px",
    fontFamily:"Poppins",
    marginTop: "4px",
    outline:"none",
    resize:"vertical",
    backgroundColor:"white",
    color:"#0F172A"
  },
  PersonalInformationDetailBox: {
    marginTop: "24px",
    padding: "30px 32px 30px 32px",
    backgroundColor: "white",
    borderRadius: "16px"
  },
  accountText: {
    fontSize: "18px",
    fontWeight: 700,
    margin: "0px",
    lineHeight: "26px"
  },
  AccPrefAndsetting: {
    fontSize: "14px",
    lineHeight: "22px",
    margin: "0px",
    color: "#475569"
  },
  pdEmText: {
    margin: "0px",
    fontWeight: 600,
    color: "#334155",
    cursor:"pointer"
  },
  EmailPasswordBox: {
    padding: "16px 0px",
    display: "flex",
    justifyContent: "space-between"
  },
  PIDesc: {
    fontSize: "14px",
    margin: "0px",
    lineHeight: "22px",
    color: "#475569"
  },
  photoImg: {
    objectFit:"cover"
  },
  InputText: {
    display: "block",
    padding: "10px 8px 10px 8px",
    borderRadius: "8px",
    width: "100%",
    height: "56px",
    marginTop: "4px",
    fontFamily:"Poppins",
    outline: "none",
    backgroundColor:"white",
    color:"#1E293B"
  },
  textinputBoxedCountryCodePN: {
    backgroundColor: "white",
    borderRadius: "8px",
    width: '120px',
    height: '55px'
  },
  cancelBtn: {
    color: "#237182",
    fontWeight: 600,
    textTransform: "capitalize",
    fontFamily: "Poppins",
  },
  saveChanges: { 
    color: "white", 
    fontWeight: 600, 
    backgroundColor: "#237182", 
    textTransform: "capitalize", 
    fontFamily: "Poppins", 
    marginLeft: "24px" 
  },
  saveChangesDisabled: { 
    color: '#64748B',
    backgroundColor: '#F1F5F9',
    fontWeight: 600, 
    textTransform: "capitalize", 
    fontFamily: "Poppins", 
    marginLeft: "24px",
  },
  cancelSaveBtn:{
    ...(window.innerWidth < 600 && {
      marginTop: '25px'
    })
  },
  replaceRemovePhoto:{
    whiteSpace: "nowrap", 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center", 
    gap: "8px",
    borderRadius:"8px"
  },
  PersonalInfoActualPhoto:{ 
    overflow:"hidden",
    width:"100%",
    height: "100%",
    borderRadius: "50%",
    backgroundColor:"white"
  },
  errorShow:{ 
    color: "#DC2626",
    margin: "0px", 
    fontSize:"12px" 
  },
  modalPaper:{
    position: 'absolute',
    width: 400,
    backgroundColor: "#fff",
    border: '2px solid #000',
    padding: "10px",
    top:"50%",
    left:"50%",
    transform:"translate(-50%,-50%)"
  }
}
const displayDataErrorMessage = () => {
  return(
    <Box style={{display: 'none'}}>
      <Typography>No data found.</Typography>
    </Box>
  );
}

const displayApiErrorMessage = () => {
  return(
    <Box style={{display: 'none'}}>
      <Typography>Api response failed.</Typography>
    </Box>
  );
}
// Customizable Area End
